export const useNumbers = () => {
  const navigatorLanguage = window.navigator.language;
  const currency = 'EUR';

  const formatNumberToIsoNumber = (number: number, fractionDigits: number = 0) => {
    return new Intl.NumberFormat(navigatorLanguage, {
      maximumFractionDigits: fractionDigits,
    }).format(number);
  };

  const formatNumberToIsoEuroNumber = (number: number) => {
    return new Intl.NumberFormat(navigatorLanguage, {
      style: 'currency',
      currency: currency,
      maximumFractionDigits: 0,
    }).format(number);
  };

  const formatCo2 = (co2: number) => {
    if (co2 > 2000 && co2 < 100000) return `${formatNumberToIsoNumber(co2 / 1000, 2)} kgCO₂`;
    else if (co2 > 100000) return `${formatNumberToIsoNumber(co2 / 1000000, 2)} TCO₂`;
    return `${formatNumberToIsoNumber(co2, 2)} gCO₂`;
  };

  const formatConsumption = (consumption: number) => {
    if (consumption > 1000 && consumption < 1000000) return `${formatNumberToIsoNumber(consumption / 1000, 2)} MWh`;
    else if (consumption > 1000000) return `${formatNumberToIsoNumber(consumption / 1000000, 2)} TWh`;
    return `${formatNumberToIsoNumber(consumption, 2)} Wh`;
  };

  return { formatNumberToIsoNumber, formatNumberToIsoEuroNumber, formatCo2, formatConsumption };
};
